import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import ProgressBar from '@ramonak/react-progress-bar'
import { TiLockClosedOutline, TiLockOpenOutline } from 'react-icons/ti'

function InProgress({ title, completed, maxCompleted, lock, baseBgColor }) {
    return (
        <div className="relative w-full">
            {!lock ? (
                <div>
                    {title}
                    <ProgressBar
                        completed={completed}
                        bgColor="#36300f"
                        height="9px"
                        borderRadius="0"
                        isLabelVisible={false}
                        labelColor="#e80909"
                        baseBgColor={baseBgColor}
                        labelSize="8"
                        maxCompleted={maxCompleted}
                        customLabel=""
                    />
                </div>
            ) : (
                <div className="text-gray-400">
                    {title}
                    <TiLockClosedOutline
                        fontSize={28}
                        className="absolute left-1/2 top-1/4"
                    />
                </div>
            )}
        </div>
    )
}

function TimeLineStake({
    period,
    startTime,
    isClaimed,
    claimTime,
    currentTimeStamp,
}) {
    //const currentTimeStamp = Math.floor(Date.now() / 1000)

    let diffTime = (isClaimed ? claimTime : currentTimeStamp) - startTime
    let bar1, bar2, bar3
    let isFinish = false

    // Not claimed
    if (diffTime / period >= 2) {
        bar1 = period * 2

        if (diffTime / period >= 3) {
            //---- BAR 3 CONDITION
            if (diffTime / period >= 4) {
                bar2 = period
                bar3 = period
                isFinish = true
            } else {
                bar2 = period
                bar3 = diffTime - period * 3
            }
        } else {
            //---- BAR 2 CONDITION
            bar2 = diffTime - period * 2
            bar3 = 0
            isFinish = false
        }
    } else {
        bar1 = diffTime
        bar2 = 0
        bar3 = 0
    }

    let baseBgColor = '#aaaaaa'

    return (
        <div className="w-full flex flex-col items-center border-0 p-3 drop-shadow-2xl shadow-cyan-500/50">
            <div className="w-full grid grid-flow-row grid-cols-9 gap-0 -mt-3 ">
                <div className="relative col-span-1 w-1/12  border-0 h-[9px] z-50 bg-[#36300f] rounded-l-xl place-self-end scale-75">
                    <div className="absolute -top-3 -left-4 text-white col-span-1  p-2 w-8 border-0  rounded-full font-bold text-xs  self-end text-center overflow-hidden scale-105  transition-all bg-[#36300f]">
                        0
                    </div>
                </div>
                <div className="relative col-span-3 border-0 scale-y-75">
                    <InProgress
                        completed={bar1}
                        maxCompleted={period * 2}
                        lock={false}
                        baseBgColor={'#aaaaaa'}
                    />
                </div>
                <div className="relative col-span-2 border-0 scale-y-75">
                    <InProgress
                        completed={bar2}
                        maxCompleted={period}
                        lock={false}
                        baseBgColor={'#aaaaaa'}
                    />
                    <div
                        className={`absolute -top-3 -left-5 text-white scale-x-75 z-50 col-span-1 p-2 w-8 border-0 bg-white rounded-full font-bold text-xs self-end text-center overflow-hidden scale-105  transition-all ${
                            bar1 == period * 2 ? 'bg-[#36300f]' : 'bg-[#aaaaaa]'
                        }`}
                    >
                        30
                    </div>
                </div>
                <div className="relative col-span-2 border-0 scale-y-75">
                    <InProgress
                        completed={bar3}
                        maxCompleted={period}
                        lock={false}
                        baseBgColor={baseBgColor}
                    />
                    <div
                        className={`absolute -top-3 -left-3 text-white scale-x-75 col-span-1 p-2 w-8 border-0 bg-white rounded-full font-bold text-xs  self-end text-center overflow-hidden scale-105  transition-all ${
                            bar2 == period ? 'bg-[#36300f]' : 'bg-[#aaaaaa]'
                        }`}
                    >
                        45
                    </div>
                </div>
                <div
                    className={`relative col-span-1 w-1/12 border-0 h-[9px] scale-75 rounded-r-xl ${
                        isFinish ? 'bg-[#36300f]' : 'bg-[#aaaaaa]'
                    } `}
                >
                    <div
                        className={`absolute -top-3 -left-1 text-white col-span-1 p-2 w-8 border-0 rounded-full font-bold text-xs self-end text-center overflow-hidden scale-105  transition-all ${
                            isFinish ? 'bg-[#36300f]' : 'bg-[#aaaaaa]'
                        } `}
                    >
                        60
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeLineStake
