import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import AnimatedNumber from "animated-number-react";
import { FiRefreshCw } from "react-icons/fi";
import { BsChevronCompactDown } from "react-icons/bs";
import {
  AiOutlineLoading3Quarters,
  AiFillStar,
  AiOutlineStar,
} from "react-icons/ai";
import axios, { Axios } from "axios";

//const erc1155Stake = '0x92053fc7aCBb30181eA67EE136ED71119640f102' // BSC Testnet Stake Addr
//const erc1155Stake = '0x4a2EF456014c3eFB798F217428764F933834F8CC' //BSC Mainnet
const erc1155Stake = process.env.REACT_APP_NFT1155STAKE_CONTRACT;
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`;
function separator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};
const abiStake = require("../abi/abiStake");

function ACard({ _id, _num }) {
  const _limit = _id < 5 ? 10 : 30;
  const _cardName = [
    "ANGEL BOOT",
    "ANGEL GUARD",
    "ANGEL AMULET",
    "ANGEL HALO",
    "ATHENA SHIELD",
    "ATHENA BOOT",
    "ARES RING",
    "ARES AMULET",
    "APOLLO ARMOR",
    "APOLLO HELMET",
  ];

  return (
    <div className="drop-shadow-lg col-span-1 ">
      <div className="item-bg border-0 rounded-t-lg shadow-lg drop-shadow-sm w-full">
        <div className="text-xs text-yellow-400 flex flex-row justify-end m-1 mt-2">
          {_id > 4 ? <AiOutlineStar /> : <AiFillStar />}
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <img
          width="120px"
          height="120px"
          className="cardLeft transition-all duration-500  hover:scale-150 "
          src={`https://nft-assets.crypterium.game/items/i/` + _id}
        />
      </div>

      <div className="flex flex-col w-full justify-center text-center p-1 border-0 rounded-b-lg bg-white text-black text-xl font-bold">
        <span className="text-xs text-yellow-500">{_cardName[_id - 1]}</span>
        <span className="flex flex-row justify-center">
          <AnimatedNumber
            value={_limit - _num}
            formatValue={formatValue}
            duration="500"
          />
          /{_limit}
        </span>
      </div>
    </div>
  );
}

function RewardCount({}) {
  const [account, setAccount] = useState();
  const [state, setState] = useState(0);
  const [idsArray, setIdsArray] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const wc = useSelector((state) => state.walletConnect.value);

  let provider;
  let ethereum = window.ethereum;
  let tempSigner;

  if (!!wc.account) {
    ethereum = wc.provider;
    provider = wc.web3Provider;
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state));
  } else if (isMetaMaskInstalled()) {
    ethereum = window.ethereum;
    provider = new ethers.providers.Web3Provider(ethereum);
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state));
  }

  useEffect(() => {
    if (!!wc.account || isMetaMaskInstalled()) {
      getCurrentAccount();
    }
    return () => {};
  }, [state, wc.account, account]);

  useEffect(() => {
    callItemLeft();
    return () => {
      setIdsArray([]);
    };
  }, [refresh]);

  const contract = new ethers.Contract(erc1155Stake, abiStake, tempSigner);
  async function getCurrentAccount() {
    const accounts = await ethereum.request({
      method: "eth_accounts",
    });
    setAccount(accounts[0]);
    return accounts[0];
  }

  async function callItemLeft() {
    let _idsArray = [];
    const _nfts = [1, 2, 3, 4, 5, 6];
    await Promise.all(
      _nfts.map(async (id) => {
        const inStake = await getInStake(id);
        const outStake = await getTotalSupply(id);
        _idsArray[id - 1] = inStake + outStake;
        //console.log('inStake', inStake)
        //console.log('outStake', outStake)
      })
    );
    setIdsArray(_idsArray);
  }

  async function getInStake(_id) {
    const _num = await contract.callStatic.inStake(_id);
    return parseInt(_num);
  }

  async function getTotalSupply(_id) {
    const _num = await contract.callStatic.totalSupply(_id);
    return parseInt(_num);
  }

  return (
    <>
      {!!account ? (
        <div className="-mt-9 w-full h-[520px] md:h-[300px] md:w-5/6 p-5 py-9 flex flex-col justify-evenly items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl transition-all">
          {/* RELOAD */}
          <div
            onClick={() => setRefresh(!refresh)}
            className=" absolute top-1 right-0  flex flex-row text-2xl font-bold items-center bg-black/0  border-black/50 hover:scale-105  transition-all rounded-xl p-3 drop-shadow-2xl cursor-pointer break-all "
          >
            <FiRefreshCw className="hover:animate-spin" />
          </div>
          <div className=" text-black text-2xl font-bold my-2">
            Special Card Amount
          </div>
          <div className="grid grid-flow-row grid-cols-3 md:grid-cols-6 gap-1 md:gap-4">
            {idsArray.map((x, i) => (
              <ACard key={i} _id={i + 1} _num={idsArray[i]} />
            ))}
          </div>
        </div>
      ) : (
        <div className=" text-white">
          Connect Wallet to see more information
        </div>
      )}
    </>
  );
}

export default RewardCount;
