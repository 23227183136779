import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import ProgressBar from '@ramonak/react-progress-bar'
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'
import { TiLockClosedOutline, TiLockOpenOutline } from 'react-icons/ti'
import Countdown from 'react-countdown'

function InProgress({ active }) {
    return (
        <div className="relative">
            {active ? (
                <div className="w-full h-[9px] bg-[#0f63ff]" />
            ) : (
                <div className="w-full h-[9px] bg-[#aaaaaa]" />
            )}
        </div>
    )
}

function StepLineEgg({ step }) {
    let isFinish = false

    //tokens == 15000 ? (baseBgColor = '#aaaaaa') : (baseBgColor = '#d4d4d4')

    return (
        <div className="w-full flex flex-col items-center border-0 p-3 text-black transition-all transform-gpu">
            <div className="w-full grid grid-flow-row grid-cols-8 gap-0 mt-1">
                <div className="relative col-span-1 w-1/12  border-0 h-[9px] bg-[#0f63ff] rounded-l-xl place-self-end">
                    <div
                        className={`absolute -top-3 -left-4 text-white col-span-1 z-20 p-2 w-8 border-0 bg-[#0f63ff] rounded-full font-bold text-xs  self-end text-center overflow-hidden scale-105 hover:scale-125 transition-all`}
                    >
                        1
                    </div>
                </div>
                <div className="relative col-span-2 border-0">
                    <InProgress active={step >= 1} />
                </div>
                <div className="relative col-span-2 border-0">
                    <InProgress active={step > 2} />
                    <div
                        className={`absolute -top-3 -left-5 text-white col-span-1 p-2 w-8 border-0 bg-white rounded-full font-bold text-xs self-end text-center overflow-hidden scale-105 hover:scale-125 transition-all ${
                            step >= 2 ? 'bg-[#0f63ff]' : 'bg-[#aaaaaa]'
                        }`}
                    >
                        2
                    </div>
                </div>
                <div className="relative col-span-2 border-0">
                    <InProgress active={step > 4} />
                    <div
                        className={`absolute -top-3 -left-3 text-white col-span-1 p-2 w-8 border-0 bg-white rounded-full font-bold text-xs  self-end text-center overflow-hidden scale-105 hover:scale-125 transition-all ${
                            step > 3 ? 'bg-[#0f63ff]' : 'bg-[#aaaaaa]'
                        }`}
                    >
                        3
                    </div>
                </div>

                <div
                    className={`relative col-span-1 w-1/12 border-0 h-[9px] rounded-r-xl ${
                        step > 5 ? 'bg-[#0f63ff]' : 'bg-[#aaaaaa]'
                    }`}
                >
                    <InProgress active={step > 3} />
                    <div
                        className={`absolute -top-3 -left-1 text-white col-span-1 p-2 w-8 border-0 rounded-full font-bold text-xs self-end text-center overflow-hidden scale-105 hover:scale-125 transition-all ${
                            step >= 6 ? 'bg-[#0f63ff]' : 'bg-[#aaaaaa]'
                        }`}
                    >
                        4
                    </div>
                </div>
            </div>

            <div className="w-11/12 grid grid-flow-row grid-cols-7 gap-0 justify-items-center mt-3">
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-sm  origin-bottom self-end text-center transition-all">
                    Prepair
                </div>
                <div className="col-span-1 border-0"></div>
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-sm  origin-bottom self-end text-center transition-all">
                    Approve Egg
                </div>
                <div className="col-span-1 border-0"></div>
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-sm  origin-bottom self-end text-center transition-all">
                    Approve CCOIN
                </div>
                <div className="col-span-1 border-0"></div>
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-sm  origin-bottom self-end text-center transition-all">
                    Complete
                </div>
            </div>
        </div>
    )
}

export default StepLineEgg
