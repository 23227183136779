import { useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop'
import { AiOutlineClose } from 'react-icons/ai'
import { ethers } from 'ethers'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { useDispatch, useSelector } from 'react-redux'
import { getWalletConnect, clearAccount } from '../redux/walletConnect'

const isMetaMaskInstalled = () => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

// const provider = new WalletConnectProvider({
//     //infuraId: '06ba877b3d513b26464bc3384fb3e278',
//     infuraId: '836dd9508e394e8ebb3d5983bb0d08f2',
// })

function Navbar() {
    const [account, setAccount] = useState(null)
    const [error, setError] = useState()
    const [info, setInfo] = useState('')
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }
    const WC = useDispatch()
    const wc = useSelector((state) => state.walletConnect.value)
    let chain
    let web3Provider = window.ethereum

    useEffect(() => {
        if (!!wc.account) {
            setAccount(wc.account)
            WC(getWalletConnect())
            web3Provider = wc.provider
        } else if (isMetaMaskInstalled()) {
            web3Provider = window.ethereum
        }
        return () => {}
    }, [account])

    console.log('wc:', wc)

    useEffect(() => {
        console.log('wc:', wc.account)

        if (isMetaMaskInstalled()) {
            getCurrentAccount()
            web3Provider.on('accountsChanged', (accounts) => {
                setAccount(accounts[0])
                switchNetwork()
            })
        }

        return () => {}
    }, [])

    //const coin = '0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02'
    let abi = require('../abi/IERC20')

    async function walletConnectLogin() {
        await WC(getWalletConnect())
            .then(() => {
                setAccount(wc.account)
                handleClose()
            })
            .catch((err) => console.log)

        handleClose()
    }

    async function getCurrentAccount() {
        const accounts = await web3Provider.request({ method: 'eth_accounts' })
        setAccount(accounts[0])
        return accounts[0]
    }

    async function mtmLogin() {
        //handleNetworkSwitch('bsc')
        try {
            if (typeof web3Provider !== 'undefined') {
                const accounts = await web3Provider.request({
                    method: 'eth_requestAccounts',
                })
                setAccount(accounts[0])
            }
        } catch (err) {
            console.log(err)
        }
        handleClose()
        await web3Provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${Number(56).toString(16)}` }],
        })
    }

    async function switchNetwork() {
        try {
            await web3Provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${Number(56).toString(16)}` }],
            })
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await web3Provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: `0x${Number(56).toString(16)}`,
                                chainName: 'Binance Smart Chain Mainnet',
                                nativeCurrency: {
                                    name: 'Binance Chain Native Token',
                                    symbol: 'BNB',
                                    decimals: 18,
                                },
                                rpcUrls: [
                                    'https://bsc-dataseed1.binance.org',
                                    'https://bsc-dataseed2.binance.org',
                                    'https://bsc-dataseed3.binance.org',
                                    'https://bsc-dataseed4.binance.org',
                                    'https://bsc-dataseed1.defibit.io',
                                    'https://bsc-dataseed2.defibit.io',
                                    'https://bsc-dataseed3.defibit.io',
                                    'https://bsc-dataseed4.defibit.io',
                                    'https://bsc-dataseed1.ninicoin.io',
                                    'https://bsc-dataseed2.ninicoin.io',
                                    'https://bsc-dataseed3.ninicoin.io',
                                    'https://bsc-dataseed4.ninicoin.io',
                                    'wss://bsc-ws-node.nariox.org',
                                ],
                                blockExplorerUrls: ['https://bscscan.com'],
                            },
                        ],
                    })
                } catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
    }

    async function addTokenFunction() {
        try {
            const wasAdded = await web3Provider.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: '0xbFB0be698655eCdEb6dDe80831dfb3C6553c4C6D', // BSC Testnet
                        //address: '0xc209831f7349D4E200d420326B3401899Ab9Ae68', // BSC Mainnet
                        symbol: 'CCOIN',
                        decimals: 18,
                        //image: 'http://ccoin.crypterium.game/img/ccoin.png',
                        image: process.env.PUBLIC_URL + '/img/coins/ccoin.png',
                    },
                },
            })

            if (wasAdded) {
                console.log('Thanks for your interest!')
            } else {
                console.log('HelloWorld Coin has not been added')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <nav className="z-10 w-full flex flex-row fixed left-0 right-0 top-0 justify-between bg-slate-800">
            <img
                src={process.env.PUBLIC_URL + '/img/logo.png'}
                className="py-2"
            />
            {!!account ? (
                <div>
                    <p className="text-slate-400 text-lg p-3 flex flex-row">
                        <button onClick={addTokenFunction} className="mr-2">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/ccoin.png'
                                }
                                width="32"
                            />
                        </button>
                        {account.slice(0, 5) + '....' + account.slice(-4)}{' '}
                        <AiOutlineClose
                            className="cursor-pointer"
                            onClick={async () => {
                                await wc.provider.disconnect()
                                WC(clearAccount())
                                setAccount(null)
                            }}
                        />
                    </p>
                    <p className="text-red-600 font-bold animate-pulse flex flex-row">
                        {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="sc-m6ivbz-6 gWhSnL"
                        >
                            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                        </svg> */}
                        {error}
                    </p>
                </div>
            ) : (
                <div className="text-white md:flex  list-none flex-row justify-between items-center">
                    <button
                        id="connectButton"
                        className="bg-[#36300f] my-2 py-1 px-7 mx-2 rounded-full items-center justify-center flex cursor-pointer hover:bg-[#ffae00]"
                        onClick={() => {
                            setInfo('Connect to a wallet')
                            handleToggle()
                        }}
                    >
                        Connect Wallet
                    </button>
                </div>
            )}

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
                //onClick={() => handleClose()}
            >
                <div className="bg-white flex flex-col m-4 items-center text-center text-gray-900 text-lg p-3 rounded-lg md:w-2/5 w-full display-linebreak">
                    <div className="w-full justify-end flex -scroll-my-9">
                        <AiOutlineClose
                            className="cursor-pointer"
                            onClick={() => handleClose()}
                        />
                    </div>
                    <div className="text-gray-500 text-md my-2 font-bold">
                        {info}
                    </div>
                    <button
                        id="mtmBtn"
                        className="bg-white w-4/5 shadow-md border my-2 py-2 px-7 justify-between rounded-lg items-center flex cursor-pointer hover:bg-[#bffeff]"
                        onClick={() => {
                            if (isMetaMaskInstalled()) {
                                mtmLogin()
                            } else {
                                setInfo("Metamask isn't install yet")
                            }
                        }}
                    >
                        Metamask
                        <img
                            src={process.env.PUBLIC_URL + '/img/metamask.png'}
                            width="24"
                        />
                    </button>

                    <button
                        id="wcBtn"
                        className="bg-white w-4/5 shadow-md border my-2 py-2 px-7 justify-between rounded-lg items-center flex cursor-pointer hover:bg-[#bffeff]"
                        onClick={() => {
                            walletConnectLogin()
                        }}
                    >
                        WalletConnect
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                '/img/walletConnectIcon.svg'
                            }
                            width="24"
                        />
                    </button>

                    <div className="text-gray-500 text-xs my-2">
                        By connecting your wallet, you agree to our
                        <br />
                        <a href="#"> Term of Service</a> and
                        <a href="#"> Privacy Policy</a>
                    </div>
                </div>
                <br />
            </Backdrop>
        </nav>
    )
}

export default Navbar
