import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import AnimatedNumber from 'animated-number-react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { AiOutlineLoading3Quarters, AiOutlineCheck } from 'react-icons/ai'
import { BsEgg } from 'react-icons/bs'
import { FaWindowClose } from 'react-icons/fa'
import { FiRefreshCw } from 'react-icons/fi'
import { GrFormView, GrFormViewHide } from 'react-icons/gr'
import EggDetailsV2 from './EggDetailsV2'
import Countdown from 'react-countdown'
import StepLine from './Stepline'
import StepLineEgg from './SteplineEgg'
import AddNetwork from './AddNetwork'

const eggHatchAddress = '0xFda9E10304B22dcA66609e7E794A1c172fD7F452' //hh Testnet
//const eggHatchAddress = '0x510f0cd7aDAB3c7542C7a0d813E819883964EB92' //hh old
//const eggHatchAddress = '0xc20D771965a58f4433e03e7a2e0d4Ad2887Cf66E' //BSC Mainnet
const CCOIN = process.env.REACT_APP_CCOIN
const CCSX = process.env.REACT_APP_CCSX

const abi = require('../abi/eggHatch')
const IERC20 = require('../abi/IERC20')
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        /* Continue line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
    }
}

function Incubator() {
    const [error, setError] = useState()
    const [hatchersArr, setHatchersArr] = useState([])
    const [info, setInfo] = useState()
    const [account, setAccount] = useState()
    const [state, setState] = useState(0)
    const [openStake4Pet, setOpenStake4Pet] = useState(false)
    const [openStake4Egg, setOpenStake4Egg] = useState(false)
    const [openEgg4Pet, setOpenEgg4Pet] = useState(false)
    const [eggTotalSupply, setEggTotalSupply] = useState(0)
    const [isCCSX, setIsCCSX] = useState(false)
    const [CCOINBalance, setCCOINBalance] = useState(0)
    const [CCSXBalance, setCCSXBalance] = useState(0)
    const [period, setPeriod] = useState(0)
    const [hiddenClaim, setHiddenClaim] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [currentTime, setCurrentTime] = useState()
    const wc = useSelector((state) => state.walletConnect.value)

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(state))
    } else if (isMetaMaskInstalled()) {
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
        ethereum.on('accountsChanged', (accounts) => setState(state))
    }

    useEffect(() => {
        if (!!wc.account || isMetaMaskInstalled()) {
            console.clear()
            getCurrentAccount()
            hatcherToArray()
            totalSupply()
            getCCSXBalance()
            getCCOINBalance()
            getPeriod()
            getBlockTimeStamp()
            console.log('account:', account)
        }
        return () => {}
    }, [state, wc.account, account, refresh])

    useEffect(() => {
        hatcherToArray()
        totalSupply()
        return () => {}
    }, [])

    async function getCurrentAccount() {
        const accounts = await ethereum.request({
            method: 'eth_accounts',
        })
        setAccount(accounts[0])
        return accounts[0]
    }

    async function getPeriod() {
        try {
            const contract = new ethers.Contract(
                eggHatchAddress,
                abi,
                tempSigner
            )
            const _period = await contract.callStatic.period()
            setPeriod(_period.toNumber())
            console.log('_period:', _period.toNumber())
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function getCCOINBalance() {
        try {
            const constract = new ethers.Contract(CCOIN, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setCCOINBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function getCCSXBalance() {
        try {
            const constract = new ethers.Contract(CCSX, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )
            setCCSXBalance(parseInt(ethers.utils.formatEther(balance)))
        } catch (err) {
            console.log('err: ', err)
        }
    }

    async function signMgs() {
        const message = 'The Smallest Parts Are Every Where!'
        await tempSigner.signMessage(message).then((signature) => {
            console.log('Signature:', signature)
            // Recover Address from signature
            const result = ethers.utils.verifyMessage(message, signature)
            console.log('Recovered Address:', result)
        })
    }

    async function hatcherToArray() {
        const _hatchersLength = await hatchersLength()

        let _hatcher = {}
        let _hatcherArr = []
        let _dataToArr = []
        for (let i = 0; i < _hatchersLength; i++) {
            await hatchers(i).then((result) => {
                _dataToArr[i] = result.split(',')
                _hatcher = {
                    eggId: parseInt(_dataToArr[i][0]),
                    tokens: ethers.utils.formatEther(_dataToArr[i][1]),
                    startTime: parseInt(_dataToArr[i][2]),
                    stopTime: parseInt(_dataToArr[i][3]),
                    neverClaimed: _dataToArr[i][4] === 'true',
                }
            })
            _hatcherArr.push(_hatcher)
        }

        setHatchersArr(_hatcherArr)
    }

    async function hatchers(_num) {
        const contract = new ethers.Contract(eggHatchAddress, abi, tempSigner)
        const hatchers = await contract.callStatic.hatchers(account, _num)
        return hatchers.toString()
    }

    async function hatchersLength() {
        const contract = new ethers.Contract(eggHatchAddress, abi, tempSigner)
        const hatchersLength = await contract.callStatic.hatchersLength(account)
        return hatchersLength.toString()
    }

    async function totalSupply() {
        const contract = new ethers.Contract(eggHatchAddress, abi, tempSigner)
        const result = await contract.callStatic.totalSupply()
        //console.log('hatchersLength', hatchersLength.toString())
        setEggTotalSupply(parseInt(result.toString()))
        //console.log('totalSupply:', parseInt(eggTotalSupply))
        return parseInt(result.toString())
    }

    async function getBlockTimeStamp() {
        const contract = new ethers.Contract(eggHatchAddress, abi, tempSigner)
        const blockTimeStamp = await contract.callStatic.getBlockTimeStamp()
        setCurrentTime(parseInt(blockTimeStamp))
        console.log('blockTimeStamp', parseInt(blockTimeStamp))
        return parseInt(blockTimeStamp)
    }

    async function getCOINApprove(_amount) {
        try {
            const constract = new ethers.Contract(
                isCCSX ? CCSX : CCOIN,
                IERC20,
                tempSigner
            )
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )

            if (
                parseInt(ethers.utils.formatEther(balance)) >= parseInt(_amount)
            ) {
                const checkAllowance = await constract.callStatic.allowance(
                    account,
                    eggHatchAddress
                )

                if (
                    parseInt(_amount) >
                    parseInt(ethers.utils.formatEther(checkAllowance))
                ) {
                    setInfo('Please approve your CCOIN')
                    const tx = await constract.approve(
                        eggHatchAddress,
                        //ethers.utils.parseUnits(String(_amount), 'ether')
                        balance
                    )
                    setInfo('Waiting for block confirmation')
                    const receipt = await tx.wait(1).then((tx) => {
                        setState(3)
                        setInfo('Please Transfer your Stake to start breeding')
                    })
                } else {
                    setState(3)
                    setInfo('Please Transfer your Stake to start breeding')
                }
            } else {
                setInfo('')
                setError('Insufficient COIN')
                setState(1)
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function getCOINEggApprove(_amount) {
        try {
            const constract = new ethers.Contract(CCOIN, IERC20, tempSigner)
            const balance = await constract.callStatic.balanceOf(
                tempSigner.getAddress()
            )

            if (
                parseInt(ethers.utils.formatEther(balance)) >= parseInt(_amount)
            ) {
                const checkAllowance = await constract.callStatic.allowance(
                    account,
                    eggHatchAddress
                )

                if (
                    parseInt(_amount) >
                    parseInt(ethers.utils.formatEther(checkAllowance))
                ) {
                    setInfo('Please approve your CCOIN')
                    const tx = await constract.approve(
                        eggHatchAddress,
                        //ethers.utils.parseUnits(String(_amount), 'ether')
                        balance
                    )
                    setInfo('Waiting for block confirmation')
                    const receipt = await tx.wait(1).then((tx) => {
                        setState(5)
                        setInfo('Transfer your Stake to start breeding')
                    })
                } else {
                    setState(5)
                    setInfo('Transfer your Stake to start breeding')
                }
            } else {
                setInfo('')
                setError('Insufficient COIN.')
                setState(1)
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function getEggApprove(_id, _amount) {
        try {
            const constract = new ethers.Contract(
                eggHatchAddress,
                abi,
                tempSigner
            )
            const eggOwner = await constract.callStatic.ownerOf(_id)
            const isHatched = await constract.callStatic.isHatched(_id)

            if (eggOwner.toLowerCase() == account.toLowerCase()) {
                // Check Egg Ownership
                if (!isHatched) {
                    // Check NFT is Egg or pet
                    setInfo('Check avaliable CCOIN Balance')
                    const CCOINConstract = new ethers.Contract( // Check CCOIN Balance
                        CCOIN,
                        IERC20,
                        tempSigner
                    )
                    const balance = await CCOINConstract.callStatic.balanceOf(
                        tempSigner.getAddress()
                    )

                    if (
                        parseInt(ethers.utils.formatEther(balance)) >=
                        parseInt(_amount)
                    ) {
                        const checkAllowance =
                            await constract.callStatic.getApproved(_id)

                        if (
                            eggHatchAddress.toLocaleLowerCase() ==
                            checkAllowance.toLocaleLowerCase()
                        ) {
                            // Already got Egg approve
                            setState(3)
                            setInfo('Approve CCoin Allowance')
                        } else {
                            // Egg not approve yet
                            setInfo('Please approve your Egg')
                            const tx = await constract.approve(
                                eggHatchAddress,
                                _id
                            )
                            setInfo('Waiting for block confirmation')
                            const receipt = await tx.wait(1).then((tx) => {
                                setState(3)
                                setInfo('Approve CCoin Allowance')
                            })
                        }
                    } else {
                        setInfo('')
                        setError('Insufficient COIN')
                        setState(1)
                    }
                } else {
                    setInfo('')
                    setError("Your pet : Hey I'm already hatched! ")
                    setState(1)
                }
            } else {
                setInfo('')
                setError('Sorry, The Egg id is not yours.')
                document.getElementById('_eggID').disabled = false
                setState(1)
            }
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function addHatchers(_num, _option) {
        try {
            const constract = new ethers.Contract(
                eggHatchAddress,
                abi,
                tempSigner
            )
            const tx = await constract.AddHatchers(_num, _option)
            setState(4)
            setInfo('Waiting for block confirmation')

            const receipt = await tx.wait(1).then((tx) => {
                setState(5)
                setInfo('Congratulation, your egg is now breeding.')
            })
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }

    async function addEgg(_id) {
        try {
            const constract = new ethers.Contract(
                eggHatchAddress,
                abi,
                tempSigner
            )
            const tx = await constract.addEgg(_id)
            setState(6)
            setInfo('Waiting for block confirmation')

            const receipt = await tx.wait(1).then((tx) => {
                setState(7)
                setInfo('Congratulation, your egg is now breeding.')
            })
        } catch (err) {
            console.log('err: ', err)
            setState(0)
        }
    }
    /*
    const options = {
        method: 'GET',
        url:
            'https://deep-index.moralis.io/api/v2/nft/' +
            eggHatchAddress +
            '/owners',
        params: { chain: '0x61', format: 'decimal' },
        headers: {
            accept: 'application/json',
            'X-API-Key':
                '2hr05pidx8lH2wSpcXDAKZrKE2pVI98urjqVt4hZ6lpnTkWBtv5sjtDrwXYt8dWu',
        },
    }

    axios
        .request(options)
        .then((response) => {
            //console.log(response.data)
            setMeta(response.data.result)
        })
        .catch((error) => {
            console.error(error)
        })

    console.log('Meta:', meta) */
    function CoinSelected({ isCCSX, step, amount }) {
        if (isCCSX) {
            return (
                <>
                    <span className="text-sm text-gray-700 italic "></span>
                    <div className="w-full flex flex-row justify-center py-2">
                        <div className="md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2  transition-all  opacity-25 hidden md:block">
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccoin.png'
                                    }
                                />
                                CCOIN
                            </span>
                            <span className="text-xs p-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    Your Balance :{' '}
                                </span>
                                {formatValue(CCOINBalance)}
                            </span>
                        </div>
                        <div className="md:w-1/3 w-2/3 border-2 rounded-lg mx-2 p-2 transition-all border-gray-400 bg-gray-100 drop-shadow-md scale-105">
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccsx.png'
                                    }
                                />
                                CCSX
                            </span>
                            <span className="text-xs px-2 pt-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 1 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    )}
                                    Your Balance :{' '}
                                </span>
                                {formatValue(CCSXBalance)}
                            </span>
                            <span className="text-xs px-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 3 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    )}
                                    Approve Coin
                                </span>
                                {step == 2 || step == 3 ? (
                                    formatValue(amount)
                                ) : (
                                    <div />
                                )}
                            </span>
                            <span className="text-xs px-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 5 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : step == 4 ? (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    ) : (
                                        <div className="w-5 my-1" />
                                    )}
                                    Transfer Coin to contract
                                </span>
                                {step == 4 ? formatValue(amount) : <div />}
                            </span>
                            <span className="text-xs px-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 5 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : step <= 1 ? (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    ) : (
                                        <div className="w-5 my-1" />
                                    )}
                                    Start breeding Egg
                                </span>
                                <div />
                                <div />
                                {step == 5 ? (
                                    <>
                                        <BsEgg className="text-orange-700 font-bold m-1" />{' '}
                                        +{formatValue(amount)}
                                    </>
                                ) : (
                                    <div />
                                )}
                            </span>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <span className="text-sm text-gray-700 italic "></span>
                    <div className="w-full flex flex-row justify-center py-2">
                        <div
                            className={`md:w-1/3 w-2/3 border-2 rounded-lg mx-2 p-2  transition-all border-yellow-400 bg-gray-100 drop-shadow-md scale-105
        `}
                        >
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccoin.png'
                                    }
                                />
                                CCOIN
                            </span>
                            <span className="text-xs px-2 pt-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 1 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    )}
                                    Your Balance :{' '}
                                </span>
                                {formatValue(CCOINBalance)}
                            </span>
                            <span className="text-xs px-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 3 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    )}
                                    Approve Coin
                                </span>
                                {step == 2 || step == 3 ? (
                                    formatValue(amount)
                                ) : (
                                    <div />
                                )}
                            </span>
                            <span className="text-xs px-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 5 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : step == 4 ? (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    ) : (
                                        <div className="w-5 my-1" />
                                    )}
                                    Transfer Coin to contract
                                </span>
                                {step == 4 ? formatValue(amount) : <div />}
                            </span>
                            <span className="text-xs px-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    {step >= 5 ? (
                                        <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                    ) : step <= 1 ? (
                                        <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                    ) : (
                                        <div className="w-5 my-1" />
                                    )}
                                    Start breeding Egg
                                </span>
                                <div />
                                <div />
                                {step == 5 ? (
                                    <>
                                        <BsEgg className="text-orange-700 font-bold m-1" />{' '}
                                        +{formatValue(amount)}
                                    </>
                                ) : (
                                    <div />
                                )}
                            </span>
                        </div>
                        <div
                            className={`md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 transition-all  opacity-25 hidden md:block`}
                        >
                            <span className="flex flex-row">
                                <img
                                    className="px-1"
                                    width={36}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/coins/ccsx.png'
                                    }
                                />
                                CCSX
                            </span>
                            <span className="w-full text-xs p-2 flex flex-row justify-between">
                                <span className="flex flex-row justify-center">
                                    Your Balance :{' '}
                                </span>
                                {formatValue(CCSXBalance)}
                            </span>
                        </div>
                    </div>
                </>
            )
        }
    }

    function CoinEggSelected({ step, amount }) {
        return (
            <>
                <span className="text-sm text-gray-700 italic "></span>
                <div className="w-full flex flex-row justify-center py-2">
                    <div
                        className={`md:w-2/3 w-2/3 border-2 rounded-lg mx-2 p-2  transition-all border-yellow-400 bg-gray-100 drop-shadow-md scale-105
        `}
                    >
                        <span className="flex flex-row">
                            <img
                                className="px-1"
                                width={36}
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/ccoin.png'
                                }
                            />
                            CCOIN
                        </span>
                        <span className="text-xs px-2 pt-2 flex flex-row justify-between">
                            <span className="flex flex-row justify-center">
                                {step >= 1 ? (
                                    <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                ) : (
                                    <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                )}
                                Your Balance :{' '}
                            </span>
                            {formatValue(CCOINBalance)}
                        </span>
                        <span className="text-xs px-2 flex flex-row justify-between">
                            <span className="flex flex-row justify-center">
                                {step >= 3 ? (
                                    <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                ) : (
                                    <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                )}
                                Approve Egg
                            </span>

                            {step >= 2 && step <= 5 ? (
                                'ID : ' +
                                document.getElementById('_eggID').value
                            ) : (
                                <div />
                            )}
                        </span>
                        <span className="text-xs px-2 flex flex-row justify-between">
                            <span className="flex flex-row justify-center">
                                {step >= 5 ? (
                                    <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                ) : step == 4 ? (
                                    <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                ) : (
                                    <div className="w-5 my-1" />
                                )}
                                Approve Coin
                            </span>
                            {step >= 2 && step <= 5 ? (
                                formatValue(amount)
                            ) : (
                                <div />
                            )}
                        </span>
                        <span className="text-xs px-2 flex flex-row justify-between">
                            <span className="flex flex-row justify-center">
                                {step >= 7 ? (
                                    <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                ) : step == 6 ? (
                                    <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                ) : (
                                    <div className="w-5 my-1" />
                                )}
                                Transfer Egg & Coin
                            </span>
                            {step == 6 ? (
                                'ID : ' +
                                document.getElementById('_eggID').value +
                                ' + ' +
                                formatValue(amount)
                            ) : (
                                <div />
                            )}
                        </span>
                        <span className="text-xs px-2 flex flex-row justify-between">
                            <span className="flex flex-row justify-center">
                                {step >= 7 ? (
                                    <AiOutlineCheck className="text-green-500 font-bold m-1" />
                                ) : step <= 1 ? (
                                    <AiOutlineLoading3Quarters className="animate-spin m-1" />
                                ) : (
                                    <div className="w-5 my-1" />
                                )}
                                Start breeding Egg
                            </span>
                            <div />
                            <div />
                            {step == 7 ? (
                                'ID : ' +
                                document.getElementById('_eggID').value +
                                ' + ' +
                                formatValue(amount)
                            ) : (
                                <div />
                            )}
                        </span>
                    </div>
                    <div className="md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 transition-all  opacity-25 hidden">
                        <span className="flex flex-row">
                            <img
                                className="px-1"
                                width={36}
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/coins/ccsx.png'
                                }
                            />
                            CCSX
                        </span>
                        <span className="w-full text-xs p-2 flex flex-row justify-between">
                            <span className="flex flex-row justify-center">
                                Your Balance :{' '}
                            </span>
                            {formatValue(CCSXBalance)}
                        </span>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
            <AddNetwork CCOIN={CCOIN} CCSX={CCSX} />
            {/*
            <div className="opacity-0 z-30 fixed top-18 -right-10 w-60 bg-black/50 text-white mb-10 py-5 pl-5 shadow-lg text-4xl rounded-lg text-start hidden md:flex flex-col">
                {!!eggTotalSupply ? (
                    <AnimatedNumber
                        //className="line-through "
                        value={250 - eggTotalSupply}
                        formatValue={formatValue}
                        duration="1000"
                    />
                ) : (
                    <CircularProgress />
                )}
                Eggs left
                <span className="text-xs">will end in</span>
                <span className="text-2xl">
                    <Countdown date={time} />
                </span>
                <span className="text-xs">Day : Hrs : Mins : Secs</span>
                </div> */}
            <div className="md:hidden z-30 fixed top-28 -right-12 pb-3 p-1 px-2 -rotate-90 bg-black/50 text-white rounded-lg">
                <AnimatedNumber
                    //className="line-through "
                    value={250 - eggTotalSupply}
                    formatValue={formatValue}
                    duration="1000"
                />
                Eggs left
            </div>
            <div className="md:w-8/12 w-full border-0 flex md:flex-row flex-col justify-between px-5 my-20 bg-black/30 rounded-md">
                <div
                    onClick={() => {
                        setOpenStake4Pet(true)
                        setOpenStake4Egg(false)
                        setOpenEgg4Pet(false)
                        setState(1)
                        setError('')
                        setInfo('Stake 15,000 CCOIN')
                    }}
                    className="w-full  justify-center  items-center drop-shadow-xl rounded-lg p-1 m-2 md:my-10 my-5 transition-all duration-200 hover:scale-110 cursor-pointer"
                >
                    <img
                        className="scale-100"
                        src={process.env.PUBLIC_URL + '/img/ad01.png'}
                        width="300px"
                    />
                </div>

                <div
                    onClick={() => {
                        setOpenStake4Pet(false)
                        setOpenStake4Egg(true)
                        setOpenEgg4Pet(false)
                        setState(1)
                        setError('')
                        setInfo('Stake 10,000 CCOIN')
                    }}
                    className="w-full  justify-center  items-center drop-shadow-2xl rounded-lg p-1 m-2 md:my-10 my-5 transition-all duration-200 hover:scale-110 cursor-pointer"
                >
                    <img
                        className="scale-100"
                        src={process.env.PUBLIC_URL + '/img/ad02.png'}
                        width="300px"
                    />
                </div>

                <div
                    onClick={() => {
                        setOpenStake4Pet(false)
                        setOpenStake4Egg(false)
                        setOpenEgg4Pet(true)
                        setState(1)
                        setError('')
                        setInfo('Stake 5,000 CCOIN + Egg')
                        document.getElementById('_eggID').value = ''
                        document.getElementById('_eggID').disabled = false
                    }}
                    className="w-full  justify-center  items-center drop-shadow-xl rounded-lg p-1 m-2 md:my-10 my-5 transition-all duration-200 hover:scale-110 cursor-pointer"
                >
                    <img
                        className="scale-100"
                        src={process.env.PUBLIC_URL + '/img/ad03.png'}
                        width="300px"
                    />
                </div>
            </div>
            <div className="mt-5 w-full md:w-5/6 p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl transition-all">
                <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
                    M y H a t c h
                </div>
                <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
                    M y H a t c h
                </div>
                <div className="md:hidden -mt-14 text-3xl font-bold text-yellow-700 blur-sm">
                    M y H a t c h
                </div>
                <div className="md:hidden -mt-10 text-3xl font-bold text-white z-40">
                    M y H a t c h
                </div>
                <div className="sticky top-20 w-full z-50 -mt-5 text-black bg-slate-600">
                    {/* RELOAD */}
                    <div
                        onClick={() => setRefresh(!refresh)}
                        className="bg-neutral-100 bg-opacity-70 backdrop-blur-xl absolute top-9 right-0  flex flex-row text-2xl font-bold items-center  hover:bg-opacity-90 border-2 border-black/70 hover:scale-105  transition-all rounded-xl p-2 drop-shadow-2xl cursor-pointer break-all "
                    >
                        <FiRefreshCw className="hover:animate-spin" />
                    </div>

                    {/* HIDDEN CLAIMEM */}
                    <div
                        onClick={() => setHiddenClaim(!hiddenClaim)}
                        className="bg-neutral-100 bg-opacity-70 backdrop-blur-xl absolute top-9 right-12  flex flex-row text-2xl font-bold items-center  hover:bg-opacity-90 border-2 border-black/70 hover:scale-105  transition-all rounded-xl p-2 drop-shadow-2xl cursor-pointer break-all "
                    >
                        {hiddenClaim ? (
                            <GrFormViewHide className="scale-125" />
                        ) : (
                            <GrFormView className="scale-125" />
                        )}
                    </div>
                </div>

                <div className="mt-20 md:w-5/6 w-full grid grid-flow-row md:grid-cols-3 grid-cols-2 gap-1 mb-20 justifyitem-center overflow-visible">
                    {!!account
                        ? hatchersArr.map((result, i) => (
                              <div
                                  className={` ${
                                      hiddenClaim && result.stopTime > 0
                                          ? 'hidden'
                                          : ''
                                  }`}
                              >
                                  <EggDetailsV2
                                      address={account}
                                      eggHatchAddress={eggHatchAddress}
                                      i={i}
                                      key={i * 300}
                                      eggId={result.eggId}
                                      tokens={result.tokens}
                                      startTime={result.startTime}
                                      stopTime={result.stopTime}
                                      neverClaimed={result.neverClaimed}
                                      currentTime={currentTime}
                                      period={period}
                                  />
                              </div>
                          ))
                        : null}
                </div>
            </div>
            {/*<StakeAd />

                <Footer />*/}
            {/*///////////////////// STAKE FOR PET //////////////////////////// */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openStake4Pet && state != 0}
                className="flex flex-col"
            >
                <div className="bg-white flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
                    <div
                        onClick={() => setState(0)}
                        className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
                            !!account ? 'hidden' : null
                        }`}
                    >
                        Please Connect Wallet
                        <br />
                        <br />
                        <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
                            Close
                        </span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                        <div />
                        <div className="py-7 text-gray-800 text-3xl font-bold">
                            {info}
                            <span className="text-rose-500 font-bold">
                                {error}
                            </span>
                        </div>
                        <FaWindowClose
                            fontSize={28}
                            color="red"
                            className={`transition-all duration-100 drop-shadow-lg ${
                                state == 1 || state == 3 || state == 5
                                    ? 'cursor-pointer hover:scale-125 '
                                    : 'grayscale opacity-10'
                            }`}
                            onClick={() => {
                                state == 1 || state == 3 || state == 5
                                    ? setState(0)
                                    : setState(state)
                            }}
                        />
                    </div>
                    <StepLine step={state - 1} />
                    {state == 1 ? (
                        <>
                            <span className="text-sm text-gray-700 italic">
                                - Select your Coin to stake -
                            </span>
                            <div
                                id="coinSelect"
                                className="w-full flex flex-row justify-center py-2"
                            >
                                <div
                                    onClick={() => {
                                        setIsCCSX(false)
                                    }}
                                    className={`md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 cursor-pointer transition-all  ${
                                        isCCSX
                                            ? ' opacity-50'
                                            : 'border-yellow-400 bg-gray-100 drop-shadow-md scale-105'
                                    }`}
                                >
                                    <span className="flex flex-row">
                                        <img
                                            className="px-1"
                                            width={36}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />
                                        CCOIN
                                    </span>
                                    <span className="text-xs p-2 flex flex-row justify-between">
                                        Your Balance :{' '}
                                        <AnimatedNumber
                                            //className="line-through "
                                            value={CCOINBalance}
                                            formatValue={formatValue}
                                            duration="1000"
                                        />
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        setIsCCSX(true)
                                        console.log('true:', isCCSX)
                                    }}
                                    className={`md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 cursor-pointer transition-all  ${
                                        isCCSX
                                            ? 'border-gray-400 bg-gray-100 drop-shadow-md scale-105'
                                            : ' opacity-50'
                                    }`}
                                >
                                    <span className="flex flex-row">
                                        <img
                                            className="px-1"
                                            width={36}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccsx.png'
                                            }
                                        />
                                        CCSX
                                    </span>
                                    <span className="text-xs p-2 flex flex-row justify-between">
                                        Your Balance :{' '}
                                        <AnimatedNumber
                                            //className="line-through "
                                            value={CCSXBalance}
                                            formatValue={formatValue}
                                            duration="1000"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setState(2)
                                    setError('')
                                    setInfo('Check CCOIN Classic Allowance')
                                    getCOINApprove(15000)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white font-bold p-2 m-2 my-4 rounded-md  hover:scale-105 transition-all shadow-lg cursor-pointer"
                            >
                                Stake Now
                            </div>
                        </>
                    ) : null}
                    {state == 2 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={2}
                                amount={15000}
                            />

                            <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                <AiOutlineLoading3Quarters
                                    color="inherit"
                                    fontSize={16}
                                    className="absolute left-1/4 mx-3 animate-spin"
                                />
                                <span className="animate-pulse">Waiting</span>
                            </div>
                        </>
                    ) : null}
                    {state == 3 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={3}
                                amount={15000}
                            />

                            <div
                                onClick={() => {
                                    setState(4)
                                    //etInfo('Please approve your CCOIN Classic')
                                    addHatchers(1, isCCSX ? 2 : 4)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white font-bold p-2 m-2 my-4 rounded-md  hover:scale-105 transition-all shadow-lg cursor-pointer"
                            >
                                Transfer Coin
                            </div>
                        </>
                    ) : null}
                    {state == 4 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={4}
                                amount={15000}
                            />
                            <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                <AiOutlineLoading3Quarters
                                    color="inherit"
                                    fontSize={16}
                                    className="absolute left-1/4 mx-3 animate-spin"
                                />
                                <span className="animate-pulse">Waiting</span>
                            </div>
                        </>
                    ) : null}
                    {state == 5 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={5}
                                amount={15000}
                            />
                            <div
                                onClick={() => {
                                    setState(0)
                                    setOpenStake4Pet(false)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white font-bold p-2 m-2 hover:scale-105 transition-all my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
                            >
                                Done
                            </div>
                        </>
                    ) : null}
                    <br />
                </div>
                <br />
            </Backdrop>

            {/*///////////////////// STAKE FOR EGG //////////////////////////// */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openStake4Egg && state != 0}
                className="flex flex-col"
            >
                <div className="bg-white flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
                    <div
                        onClick={() => setState(0)}
                        className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
                            !!account ? 'hidden' : null
                        }`}
                    >
                        Please Connect Wallet
                        <br />
                        <br />
                        <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
                            Close
                        </span>
                    </div>
                    <div className="w-full flex flex-row justify-between">
                        <div />
                        <div className="py-7 text-gray-800 text-3xl font-bold">
                            {info}
                            <span className="text-rose-500 font-bold">
                                {error}
                            </span>
                        </div>
                        <FaWindowClose
                            fontSize={28}
                            color="red"
                            className={`transition-all duration-100 drop-shadow-lg ${
                                state == 1 || state == 3 || state == 5
                                    ? 'cursor-pointer hover:scale-125 '
                                    : 'grayscale opacity-10'
                            }`}
                            onClick={() => {
                                state == 1 || state == 3 || state == 5
                                    ? setState(0)
                                    : setState(state)
                            }}
                        />
                    </div>
                    <StepLine step={state - 1} />

                    {state == 1 ? (
                        <>
                            <span className="text-sm text-gray-700 italic">
                                - Select your Coin to stake -
                            </span>
                            <div
                                id="coinSelect"
                                className="w-full flex flex-row justify-center py-2"
                            >
                                <div
                                    onClick={() => {
                                        setIsCCSX(false)
                                    }}
                                    className={`md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 cursor-pointer transition-all  ${
                                        isCCSX
                                            ? ' opacity-50'
                                            : 'border-yellow-400 bg-gray-100 drop-shadow-md scale-105'
                                    }`}
                                >
                                    <span className="flex flex-row">
                                        <img
                                            className="px-1"
                                            width={36}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />
                                        CCOIN
                                    </span>
                                    <span className="text-xs p-2 flex flex-row justify-between">
                                        Your Balance :{' '}
                                        <AnimatedNumber
                                            //className="line-through "
                                            value={CCOINBalance}
                                            formatValue={formatValue}
                                            duration="1000"
                                        />
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        setIsCCSX(true)
                                        console.log('true:', isCCSX)
                                    }}
                                    className={`md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 cursor-pointer transition-all  ${
                                        isCCSX
                                            ? 'border-gray-400 bg-gray-100 drop-shadow-md scale-105'
                                            : ' opacity-50'
                                    }`}
                                >
                                    <span className="flex flex-row">
                                        <img
                                            className="px-1"
                                            width={36}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccsx.png'
                                            }
                                        />
                                        CCSX
                                    </span>
                                    <span className="text-xs p-2 flex flex-row justify-between">
                                        Your Balance :{' '}
                                        <AnimatedNumber
                                            //className="line-through "
                                            value={CCSXBalance}
                                            formatValue={formatValue}
                                            duration="1000"
                                        />
                                    </span>
                                </div>
                            </div>

                            <div
                                onClick={() => {
                                    setState(2)
                                    setError('')
                                    setInfo('Check CCOIN Classic Allowance')
                                    getCOINApprove(10000)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
                            >
                                Stake Now
                            </div>
                        </>
                    ) : null}
                    {state == 2 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={2}
                                amount={10000}
                            />
                            <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                <AiOutlineLoading3Quarters
                                    color="inherit"
                                    fontSize={16}
                                    className="absolute left-1/4 mx-3 animate-spin"
                                />
                                <span className="animate-pulse">Waiting</span>
                            </div>
                        </>
                    ) : null}

                    {state == 3 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={3}
                                amount={10000}
                            />

                            <div
                                onClick={() => {
                                    setState(4)
                                    addHatchers(1, isCCSX ? 1 : 3)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
                            >
                                Transfer Coin
                            </div>
                        </>
                    ) : null}

                    {state == 4 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={4}
                                amount={10000}
                            />
                            <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                <AiOutlineLoading3Quarters
                                    color="inherit"
                                    fontSize={16}
                                    className="absolute left-1/4 mx-3 animate-spin"
                                />
                                <span className="animate-pulse">Waiting</span>
                            </div>
                        </>
                    ) : null}

                    {state == 5 ? (
                        <>
                            <CoinSelected
                                isCCSX={isCCSX}
                                step={5}
                                amount={10000}
                            />
                            <div
                                onClick={() => {
                                    setState(0)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white font-bold p-2 m-2 my-4 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
                            >
                                Done
                            </div>
                        </>
                    ) : null}

                    <br />
                </div>
                <br />
            </Backdrop>

            {/*///////////////////// EGG FOR PET //////////////////////////// */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openEgg4Pet && state != 0}
                className="flex flex-col"
            >
                <div className="bg-white flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
                    <div
                        onClick={() => setState(0)}
                        className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
                            !!account ? 'hidden' : null
                        }`}
                    >
                        Please Connect Wallet
                        <br />
                        <br />
                        <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
                            Close
                        </span>
                    </div>

                    <div className="w-full flex flex-row justify-between">
                        <div />
                        <div className="py-7 text-gray-800 text-3xl font-bold">
                            {info}
                            <span className="text-rose-500 font-bold">
                                {error}
                            </span>
                        </div>

                        <input
                            placeholder="Egg ID"
                            type="number"
                            pattern="[0-9]*"
                            min="1"
                            step="1"
                            id="_eggID"
                            keyboardtype="decimal-pad"
                            autoComplete="off"
                            className="text-center m-2 w-1/4 active:outline-none rounded-md px-4 outline-non bg-gray-200 text-slate-500 text-3xl font-bold focus:outline-none"
                        />

                        <FaWindowClose
                            fontSize={28}
                            color="red"
                            className={`transition-all duration-100 drop-shadow-lg ${
                                state == 1 || state == 3 || state == 5
                                    ? 'cursor-pointer hover:scale-125 '
                                    : 'grayscale opacity-10'
                            }`}
                            onClick={() => {
                                state == 1 || state == 3 || state == 5
                                    ? setState(0)
                                    : setState(state)
                            }}
                        />
                    </div>
                    <StepLineEgg step={state - 1} />

                    {state == 1 ? (
                        <>
                            <span className="text-sm text-gray-700 italic">
                                - Only CCOIN Accept -
                            </span>
                            <div
                                id="coinSelect"
                                className="w-full flex flex-row justify-center py-2"
                            >
                                <div className="md:w-1/3 w-2/3 border-2 rounded-lg mx-2 p-2 transition-all border-yellow-400 bg-gray-100 drop-shadow-md scale-105">
                                    <span className="flex flex-row">
                                        <img
                                            className="px-1"
                                            width={36}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccoin.png'
                                            }
                                        />
                                        CCOIN
                                    </span>
                                    <span className="text-xs p-2 flex flex-row justify-between">
                                        Your Balance :{' '}
                                        <AnimatedNumber
                                            //className="line-through "
                                            value={CCOINBalance}
                                            formatValue={formatValue}
                                            duration="1000"
                                        />
                                    </span>
                                </div>
                                <div className="md:w-1/3 w-1/2 border-2 rounded-lg mx-2 p-2 transition-all opacity-50 hidden">
                                    <span className="flex flex-row">
                                        <img
                                            className="px-1"
                                            width={36}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/img/coins/ccsx.png'
                                            }
                                        />
                                        CCSX
                                    </span>
                                    <span className="text-xs p-2 flex flex-row justify-between">
                                        Your Balance :{' '}
                                        <AnimatedNumber
                                            //className="line-through "
                                            value={CCSXBalance}
                                            formatValue={formatValue}
                                            duration="1000"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    if (
                                        document.getElementById('_eggID')
                                            .value == ''
                                    ) {
                                        setInfo('')
                                        setError('Invalid Egg ID')
                                    } else {
                                        setIsCCSX(false)
                                        setState(2)
                                        setError('')
                                        setInfo('Check Egg Ownership')
                                        document.getElementById(
                                            '_eggID'
                                        ).disabled = true
                                        getEggApprove(
                                            document.getElementById('_eggID')
                                                .value,
                                            5000
                                        )
                                    }
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
                            >
                                Start
                            </div>
                        </>
                    ) : null}
                    {state == 2 ? (
                        <>
                            <CoinEggSelected step={2} amount={5000} />
                            <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                <AiOutlineLoading3Quarters
                                    color="inherit"
                                    fontSize={16}
                                    className="absolute left-1/4 mx-3 animate-spin"
                                />
                                <span className="animate-pulse">Waiting</span>
                            </div>
                        </>
                    ) : null}

                    {state == 3 ? (
                        <>
                            <CoinEggSelected step={3} amount={5000} />
                            <div
                                onClick={() => {
                                    setState(4)
                                    setInfo('Wait for your CCOIN approval')
                                    getCOINEggApprove(5000)
                                    //addHatchers(1, 1)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
                            >
                                Continue
                            </div>
                        </>
                    ) : null}

                    {state == 4 ? (
                        <>
                            <CoinEggSelected step={4} amount={5000} />
                            <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                <AiOutlineLoading3Quarters
                                    color="inherit"
                                    fontSize={16}
                                    className="absolute left-1/4 mx-3 animate-spin"
                                />
                                <span className="animate-pulse">Waiting</span>
                            </div>
                        </>
                    ) : null}

                    {state == 5 ? (
                        <>
                            <CoinEggSelected step={5} amount={5000} />
                            <div
                                onClick={() => {
                                    setState(6)
                                    setInfo('Waiting for block confirmation')
                                    addEgg(
                                        document.getElementById('_eggID').value
                                    )
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white font-bold p-2 m-2 my-4 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
                            >
                                Transfer
                            </div>
                        </>
                    ) : null}

                    {state == 6 ? (
                        <>
                            <CoinEggSelected step={6} amount={5000} />
                            <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                                <AiOutlineLoading3Quarters
                                    color="inherit"
                                    fontSize={16}
                                    className="absolute left-1/4 mx-3 animate-spin"
                                />
                                <span className="animate-pulse">Waiting</span>
                            </div>
                        </>
                    ) : null}

                    {state == 7 ? (
                        <>
                            <CoinEggSelected step={7} amount={5000} />
                            <div
                                onClick={() => {
                                    setState(0)
                                }}
                                className="w-2/3 bg-[#0f63ff] text-white p-2 font-bold m-2 my-4 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
                            >
                                Done
                            </div>
                        </>
                    ) : null}

                    <br />
                </div>
                <br />
            </Backdrop>
        </div>
    )
}

export default Incubator
